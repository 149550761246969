<div class="background-base px-2 h-full w-full flex justify-center items-start">

    <!-- document submitted-->
    <!-- document submitted-->
    <div
        class="card w-full max-w-3xl p-6 text-center mx-auto bg-white shadow-lg rounded-lg animate-fadeIn mt-8 sm:mt-16">
        <img src="assets/img/block-user.png" class="mx-auto w-16 mb-4 mt-3" alt="Alert Icon">
        <h2 @fadeInUp class="text-lg font-bold text-red-600 mb-4">
            🚨 Important Update on Your Phlenu Affiliate Account
        </h2>

        <p @fadeInUp class="text-sm text-gray-700 mb-4">
            Dear <span class="font-semibold">{{this.userInform.firstName}} {{this.userInform.lastName}}</span>,
        </p>
        <p @fadeInUp class="text-sm text-gray-700 mb-4">
            We regret to inform you that your affiliate account has been
            <span class="font-semibold text-red-500">blocked</span> due to non-compliance with our terms.
        </p>
        <p @fadeInUp class="text-sm text-gray-700 mb-4">
            If you believe this action was taken in error or have questions regarding this decision, please contact us
            at
            <a href="mailto:customer@phlenu.com" class="text-blue-500 underline">{{'customer@phlenu.com'}}</a>
            or reach out via WhatsApp: 
 
            <strong *ngIf="this.userInform.country == 'ghana'" class="block mt-3 leading-normal">+233554867581 (Ghana)</strong>
            
            <strong *ngIf="this.userInform.country != 'ghana'" class="block mt-3 leading-normal">+234818391923 (Nigeria)</strong>
            
        </p>
        <!-- <ul @fadeInUp class="text-sm text-gray-700 mb-4 list-disc list-inside">
            <li>+234818391923 (Nigeria)</li>
            <li>+233554867581 (Ghana)</li>
        </ul> -->
        <p @fadeInUp class="text-sm text-gray-700 mb-4">
            We appreciate your understanding and cooperation.
        </p>

        <p @fadeInUp class="text-sm font-bold text-gray-800">Best regards,</p>
        <p @fadeInUp class="text-sm font-bold text-gray-800">Phlenu Affiliate Team</p>

        <div class="text-center mt-4">
            <a routerLink="/login" class="btn underline text-blueColor">Back to login</a>
        </div>
    </div>

    <!-- document submitted-->

</div>