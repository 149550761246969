<div matDialogTitle class="flex justify-between items-center" mat-dialog-title>
    <h2 class="headline m-0 sm:text-xl text-lg">Customize your affiliate Link</h2> 
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-content class="py-3">
    <span class="sm:text-lg text-base">URL Preview</span>
    <div class="px-3 break-all shadow-1 mt-1 mb-3 rounded-sm py-4 border bg-base" *ngIf="this._data.generateLinkFor == 'Shopping'">
        "{{this.staticLink}}<ng-container *ngFor="let group of this.linkRow['controls'];let i = index">{{group.value.label ? '&'+group.value.label : ''}}{{group.value.value ? '='+group.value.value : ''}}</ng-container>"
    </div>
    <div class="px-3 break-all shadow-1 mt-1 mb-3 rounded-sm py-4 border bg-base" *ngIf="this._data.generateLinkFor == 'delivery'">
        "{{this.staticLink}}<ng-container *ngFor="let group of this.linkRow['controls'];let i = index">{{i == 0 ? '?' : '&'}}{{group.value.label ? group.value.label : ''}}{{group.value.value ? '='+group.value.value : ''}}</ng-container>"
    </div>
    <mat-divider class="-mx-6 mt-6 mb-2 text-border"></mat-divider>
    <form [formGroup]="this.form">
        <div class="flex justify-between items-center">
            <span class="sm:text-lg text-base">URL Customization</span>
            <button (click)="addNewRowByAction()" mat-icon-button>
                <mat-icon [icIcon]="icAdd"></mat-icon>
            </button>
        </div>
        <div class="flex flex-col overflow-y-auto" style="max-height: 250px;">
            <div formArrayName="linkRow" class="linkRow relative" *ngFor="let group of this.linkRow['controls'];let linkRowIndex = index">
                <div class="w-full border pt-3 px-3 pb-4 rounded-sm flex sm:flex-row flex-col gap-2" [formGroup]="group">
                    <div class="flex w-full flex-col">
                        <span class="text-sm">Title</span>
                        <mat-form-field appearance="outline">
                            <input appNoSpaces type="text" maxlength="15" formControlName="label" matInput required>
                            <mat-error *ngIf="group.get('label').hasError('required')">Title is required</mat-error>
                            <mat-hint><span class="text-gray text-xxs">The title can be a label such as ‘Name,’ ‘Organization,’ or ‘Country.’</span></mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="flex w-full flex-col">
                        <span class="text-sm">Information </span>
                        <mat-form-field appearance="outline">
                            <input appNoSpaces type="text" maxlength="15" formControlName="value" matInput required>
                            <mat-error *ngIf="group.get('value').hasError('required')">Information is required</mat-error>
                            <mat-hint><span class="text-gray text-xxs">The information should be the corresponding value, e.g., ‘Pitter,’ ‘Phlenu,’ or ‘Ghana.’</span></mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <div (click)="removeLinkRow(linkRowIndex)" class="absolute cursor-pointer remove_btn top-2 right-2">
                    <mat-icon [icIcon]="icRemove"></mat-icon>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button color="primary" mat-button (click)="onSubmit()" type="submit" [disabled]="this.linkRow['controls'].length != 1 ? form.invalid : false">{{this.mode == 'add' ? 'Generate Link' : 'Save'}}</button>
</mat-dialog-actions>