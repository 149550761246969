import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import icClose from '@iconify/icons-ic/twotone-close';
import icAdd from '@iconify/icons-ic/add';
import { HighlightService } from "../components/highlight/highlight.service";

@Component({
    selector: "vex-select-bank-dialog",
    templateUrl: "./select-bank-dialog.component.html",
    styleUrls: ["./select-bank-dialog.component.scss"],
})
export class SelectBankDialogComponent implements OnInit {
  icClose = icClose;
  icAdd = icAdd;

  paymentMethods = [
    {
      id : 1,
      title : 'Mobile Money',
      icon : 'assets/img/wallet.png',
      isAdded : 0,
      redirect: '/mobile-money/add',
      isSelected : 0
    },
    {
      id : 2,
      title : 'Bank',
      icon : 'assets/img/bank.png',
      isAdded : 0,
      redirect: '/bank-detail/add',
      isSelected : 0
    },
  ]
  constructor(
      @Inject(MAT_DIALOG_DATA) private data: any,
      private dialogRef: MatDialogRef<SelectBankDialogComponent>
  ) {
    
      this.paymentMethods.forEach((item)=>{
        if(item.id == 1){
          item.isAdded = data?.isMobileMoneyDetailsAdded || 0
        }
        if(item.id == 2){
          item.isAdded = data?.isBankDetailAdded || 0
        }
      }) 

      if(data?.isMobileMoneyDetailsAdded){
        this.paymentMethods.forEach((item) => item.id == 1 ? item.isSelected  = 1 : null);
      }else if(data?.isBankDetailAdded){
        this.paymentMethods.forEach((item) => item.id == 2 ? item.isSelected  = 1 : null);
      }
  }

    ngOnInit(): void {}


    onSelectMethods(item){
      if(item.isAdded){
        this.paymentMethods.forEach((ele)=> ele.isSelected = 0);
        item.isSelected = 1;
      }
    }

    onClose(){
      const selectedMethods = this.paymentMethods.filter((item)=>item.isSelected)[0];
      this.dialogRef.close(selectedMethods || null);
    }


}
