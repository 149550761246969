<h1 matDialogTitle class="relative mb-4 text-xl text-center">Attention
    <div class="absolute -top-3 -right-2">
        <button mat-icon-button>
            <mat-icon [icIcon]="icClose"></mat-icon>
          </button>
    </div>
</h1>
<mat-dialog-content>
    <div class="w-full pt-2 mb-4">
        <p class="text-center">Before submitting a payment withdrawal request, you must add your bank detail.</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="gap-3 pb-6"> 
    <button mat-raised-button *ngIf="this.isMobileMoneyShow" color="primary" [routerLink]="'/mobile-money/add'" routerLinkActive="router-link-active"  mat-dialog-close tabindex="-1">Add Mobile Money</button>
    <button mat-raised-button color="primary" [routerLink]="'/bank-detail/add'" routerLinkActive="router-link-active"  mat-dialog-close tabindex="-1">Add Bank Details</button>
</mat-dialog-actions>