import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';

@Component({
  selector: 'vex-bank-info-alert-dialog',
  templateUrl: './bank-info-alert-dialog.component.html',
  styleUrls: ['./bank-info-alert-dialog.component.scss']
})
export class BankInfoAlertDialogComponent implements OnInit {
  icClose = icClose;
  isMobileMoneyShow : boolean = false;
  constructor(
      @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<BankInfoAlertDialogComponent>
  ) { 
    if(JSON.parse(localStorage.getItem("loggedUser")).country == "ghana"){
      this.isMobileMoneyShow = true;
    }else{
      this.isMobileMoneyShow = false;
    }
  }

  ngOnInit(): void {
  }

}
