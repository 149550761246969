<div class="w-full h-full document-upload-layout p-6 overflow-auto background-base">
    <div class="text-right w-full gap-4 flex justify-end mb-4">

        <div *ngIf="isDocumentUploaded" style="display: flex; align-items: center; gap: 10px;">
            <span *ngIf="countdown !== null">You can recheck in {{ countdown }}s</span>

            <button (click)="checkForUpdate()" color="primary" class="w-40" mat-raised-button
                [disabled]="isButtonDisabled">
                <div class="flex justify-center items-center" style="min-height: 36px;">
                    <mat-progress-spinner *ngIf="isCheckingForUpdate" diameter="24"
                        mode="indeterminate"></mat-progress-spinner>
                    <span *ngIf="!isCheckingForUpdate">Check For Update</span>
                </div>
            </button>
        </div>

        <button (click)="logout()" color="primary" mat-raised-button>Logout</button>
    </div>

    <!-- document form-->
    <div @fadeInUp class="card w-full max-w-lg" *ngIf="!isDocumentUploaded">
        <div class="text-center px-6 mb-6 pt-6">
            <h2 class="text-lg font-normal leading-normal mt-2">Please upload your Document here <br /> for approval
            </h2>
        </div>

        <div [formGroup]="form" class="px-6 pb-6" fxLayout="column" fxLayoutGap="16px">

            <div fxLayout="column">

                <mat-form-field class="flex-auto mb-3">
                    <mat-label>Document Type</mat-label>
                    <mat-select class="text-capitalize" (selectionChange)="checkSelected($event.value)" required
                        formControlName="docType">
                        <mat-option class="text-capitalize" *ngFor="let doc of docList" [value]="doc">
                            {{doc}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('docType').hasError('required')">{{message.field.required}}</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-auto mb-3">
                    <mat-label>Document Number</mat-label>
                    <input type="text" formControlName="docNumber" matInput required>
                    <mat-error *ngIf="form.get('docNumber').hasError('required')">{{message.field.required}}</mat-error>
                </mat-form-field>

                <div fxLayout="column">
                    <label class="d-block">Verify Your Identity <br />
                        <small class="hint-text d-block">Aspect Ratio: 1:1 | Allowed Types: PNG, JPEG, WEBP</small>
                    </label>


                    <div class="grid pb-4 pt-2 sm:grid-cols-1 grid-cols-1">
                        <div class="profile_image w-full text-left">
                            <div class="flex justify-start mb-1 items-start">
                                <div
                                    class="flex flex-col items-center justify-center profilePicWrapper shadow-4 rounded-lg bg-lightSilverColor relative">
                                    <!-- Placeholder -->
                                    <ng-container *ngIf="!imageSrc">
                                        <div class="flex border rounded-lg flex-col items-center justify-center w-full h-full cursor-pointer transition duration-300" [ngClass]="showAllRequired ? 'border-red' : 'border-lightSilverColor'"
                                            (click)="fileInput.click()">
                                            <img src="assets/img/holdingID.svg" class="w-12 h-12 object-contain" alt="">
                                            <!-- <div class="text-gray-500 text-4xl leading-none">+</div> -->
                                            <!-- <p class="mt-1 text-sm leading-none text-gray-600">Add Selfie <br/><small>Holding your ID</small></p> -->
                                        </div>
                                    </ng-container>

                                    <!-- Uploaded Image -->
                                    <ng-container *ngIf="!isLoading ? (imageSrc) : (!isLoading && imageSrc)">
                                        <img [src]="imageSrc" alt="Profile Picture"
                                            class="w-full h-full rounded-lg object-cover" />
                                    </ng-container>

                                    <!-- Loader -->
                                    <div *ngIf="isLoading" class="loader-container rounded-lg">
                                        <div class="loader"></div>
                                    </div>


                                    <input #fileInput type="file" class="hidden" (change)="onFileChange($event)"
                                        accept="image/png, image/jpeg, image/webp" />

                                    <div class="absolute  top-0 z-10 right-0">
                                        <button mat-icon-button class="text-sm transition"
                                            *ngIf="!isLoading ? (imageSrc) : (!isLoading && imageSrc)"
                                            (click)="removeImage()">
                                            <mat-icon class="text-midBlack" style="font-size: 20px!important;"
                                                [icIcon]="icClose"></mat-icon>
                                            <!-- <mat-icon [icIcon]="icRemove"
                                                class="text-contrast-white shadow-8 text-sm"></mat-icon> -->
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <small class="hint-text d-block">Selfie of you holding your Government Id</small>
                        </div>
                    </div>
                </div>

                <div class="mb-2 mt-2">
                    <label class="d-block">Upload Document <br />
                        <small class="hint-text d-block">(JPEG, PNG, or WEBP | Max size: 5MB)</small></label>
                    <div class="flex flex-row">
                        <div class="flex-1 flex justify-start items-start text-center">
                            <label *ngIf="!docImageUrl.front"
                                class="mt-2 mb-2 rounded-lg bg-lightSilverColor image-box border"  [ngClass]="showAllRequired ? 'border-red' : 'border-lightSilverColor'">
                                <input type="file" (change)="readUrl($event, 'front')" accept="image/*"
                                    formControlName="frontImage" hidden required>
                                <!-- <mat-icon [icIcon]="icUpload" color="primary"></mat-icon> -->
                                <div>
                                    <img src="assets/img/front.svg" class="w-12 h-12 object-contain placeholder" alt="">
                                    <small class="hint-text d-block">Front</small>
                                </div>

                            </label>

                            <div *ngIf="docImageUrl.front"
                                class="mt-2 mb-2 relative rounded-lg bg-lightSilverColor image-box">
                                <img [src]="docImageUrl.front">
                                <div class="absolute -top-1 -right-1 z-10">
                                    <button (click)="removeFrontImage()" mat-icon-button>
                                        <div class="w-full h-full flex items-center justify-center">
                                            <mat-icon class="text-midBlack flex items-center justify-center" style="font-size: 20px!important;"
                                                [icIcon]="icClose"></mat-icon>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <!-- <label class="d-block mb-3 caption">Front Image</label> -->
                        </div>

                        <div class="flex-1 flex justify-start items-start text-center">
                            <label *ngIf="!docImageUrl.back" class="mt-2 mb-2 rounded-lg bg-lightSilverColor border image-box" [ngClass]="showAllRequired ? 'border-red' : 'border-lightSilverColor'">
                                <input type="file" (change)="readUrl($event, 'back')" accept="image/*"
                                    formControlName="backImage" required hidden>
                                <!-- <mat-icon [icIcon]="icUpload" color="primary"></mat-icon> -->
                                <div>
                                    <img src="assets/img/back.svg" class="w-12 h-12 object-contain placeholder" alt="">
                                    <small class="hint-text d-block">Back</small>
                                </div>
                            </label>

                            <div *ngIf="docImageUrl.back"
                                class="mt-2 mb-2 relative rounded-lg bg-lightSilverColor image-box">
                                <img [src]="docImageUrl.back">
                                <div class="absolute -top-1 -right-1 z-10">
                                    <button (click)="removeBackImage()" mat-icon-button>
                                        <div class="w-full h-full flex items-center justify-center">
                                            <mat-icon class="text-midBlack flex items-center justify-center" style="font-size: 20px!important;"
                                                [icIcon]="icClose"></mat-icon>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <!-- <label class="d-block mb-3 caption">Back Image</label> -->
                        </div>
                    </div>
                    <small class="hint-text d-block">Non blurry front and back Images of Government issued id</small>
                </div>



                <div class="leading-snug my-4">
                    <label class="d-block">Note</label><br />
                    <small class="hint-text d-block">For security and smooth payouts, please upload: <br> A copy of your
                        government-issued ID <br> A clear, non-blurry photo of yourself holding your ID <br> Your info
                        is 100% secure and used only tor verification.</small>
                </div>

                <!-- <button (click)="uploadDocumentImage()" color="primary" mat-raised-button
                    [disabled]="form.invalid && this.isDocumentSubmitting">
                    Submit
                </button> --> 
                <button (click)="uploadDocumentImage()" color="primary" mat-raised-button
                    [disabled]="form.invalid ? (form.invalid || !this.selfieImageURL) : !this.selfieImageURL ? true : this.isDocumentSubmitting"> 
                    <div class="flex justify-center items-center" style="min-height: 36px;">
                        <mat-progress-spinner *ngIf="isDocumentSubmitting" diameter="24" color="accent"
                            mode="indeterminate" class="text-contrast-white custom-spinner"></mat-progress-spinner>
                        <span *ngIf="!isDocumentSubmitting">Submit</span>
                    </div> 
                </button>
            </div>
        </div>
    </div>
    <!-- document form-->

    <!-- document submitted-->
    <div class="card w-full max-w-3xl p-6 text-center animate-fadeIn shadow-lg rounded-lg bg-[#181f2f] mx-auto mt-8 sm:mt-16"
        *ngIf="isDocumentUploaded">
        <img src="assets/img/time-left.png" alt="Waiting for Approval" class="mx-auto w-20 mb-4 mt-3 animate-bounce">

        <h2 @fadeInUp class="title text-lg font-bold text-gray-700 uppercase mb-4">
            Waiting for Approval
        </h2>

        <p @fadeInUp class="text-gray-600 leading-normal mb-6">
            Thank you <span class="font-semibold">{{this.user.firstName}} {{this.user.lastName}}</span> for uploading
            your
            documents!
            We are currently reviewing your profile to ensure everything is in order.
            This process typically takes <strong>24-72 hours</strong>, and we’ll notify you of the outcome as soon as
            possible.
        </p>

        <p @fadeInUp class="text-gray-600 leading-normal mb-4">
            You’ll receive an update via <strong>email</strong> or <strong>phone</strong> once your profile has been
            reviewed.
            In the meantime, if you have any questions or need assistance, feel free to contact us at
            <a href="mailto:customer@phlenu.com" class="text-blue-500 underline">{{'customer@phlenu.com'}}</a>
            or on WhatsApp:
            <ng-container *ngIf="this.user.country == 'ghana'; else ngnCountry">
                <strong class="block mt-3 leading-normal">+233554867581 (Ghana)</strong>
            </ng-container>
            <ng-template #ngnCountry>
                <strong class="block mt-3 leading-normal">+234818391923 (Nigeria)</strong>
            </ng-template>
        </p>

        <p @fadeInUp class="text-gray-700 leading-normal font-semibold">
            Thank you for your patience and for choosing Phlenu!
        </p>

        <div @fadeInUp class="mt-6 leading-normal">
            <h4 class="text-gray-800 font-bold">Thanks,</h4>
            <h5 class="text-gray-700">Team Phlenu</h5>
        </div>
        <!-- document submitted-->
    </div>
</div>