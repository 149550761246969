import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { fadeInUp400ms } from "../../../@vex/animations/fade-in-up.animation";
import { AuthServiceLocal } from "../../../@vex/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LocalStoreService } from "../../../@vex/services/local-store.service";
import { country, Pattern } from "../../../@vex/utils/systemEnums";
import { Messages } from "../../../@vex/utils/message";
import icImage from "@iconify/icons-ic/twotone-image";
import icClose from "@iconify/icons-ic/close";
import icRemove from "@iconify/icons-ic/delete";
import icUpload from "@iconify/icons-fa-solid/upload";

@Component({
    selector: "vex-register",
    templateUrl: "./document-upload.component.html",
    styleUrls: ["./document-upload.component.scss"],
    animations: [fadeInUp400ms],
})
export class DocumentUploadComponent implements OnInit, AfterViewInit {
    year = new Date().getFullYear();
    country = country;
    icImage = icImage;
    icUpload = icUpload;
    selfieImageURL: string;
    form: FormGroup;
    icRemove = icRemove;
    pattern = Pattern;
    message = Messages;
    docListForGhana = ["Ghana card", "Passport", "Drivers License"];
    docListForNigeria = [
        "Passport",
        "National Identification Number (NIN)",
        "Driver’s License",
    ];
    docImage: any = {
        front: "",
        back: "",
    };
    docImageUrl: any = {
        front: "",
        back: "",
    };
    docUrl: any = {
        front: "",
        back: "",
    };
    isDocumentUploaded;
    docList = [];
    user;
    imageSrc;
    isLoading: boolean = false;
    isDocumentSubmitting: boolean = false;
    isCheckingForUpdate: boolean = false;
    isButtonDisabled = false;
    icClose = icClose;
    countdown: number | null = null;
    showAllRequired:boolean = false;
 
    constructor(
        private router: Router,
        private cd: ChangeDetectorRef,
        private fb: FormBuilder,
        private snackbar: MatSnackBar,
        private auth: AuthServiceLocal,
        private store: LocalStoreService
    ) {
        this.user = this.auth.getUser();
        console.log("this.user===>", this.user);
        if (this.user.status == 2) {
            this.router.navigate(["/rejected-user"]);
        } else {
            if (this.user.isDocumentUploaded === 1) {
                this.isDocumentUploaded = true;
                // this.isDocumentUploaded = false;
            } else {
                this.isDocumentUploaded = false;
                // this.isDocumentUploaded = true;
            }

            this.setDocumentList();
        }
    }

    ngOnInit() {
        this.form = this.fb.group({
            docType: ["", Validators.required],
            docNumber: ["", Validators.required],
            frontImage: "",
            backImage: "",
        });

        setTimeout(() => {
            this.form.get('docType').markAsTouched();
            this.form.get('docNumber').markAsTouched();
            this.showAllRequired = true;
        }, 2000);
    }

    setDocumentList() {
        if (this.user.country.toLowerCase() === country.Ghana) {
            this.docList = this.docListForGhana;
        } else if (this.user.country.toLowerCase() === country.Nigeria) {
            this.docList = this.docListForNigeria;
        }
    }

    checkForUpdate() {
        this.isCheckingForUpdate = true;
        this.isButtonDisabled = true;
        this.countdown = null; // Hide countdown initially
 
        this.auth.checkForChangeStatus({affiliateId: this.user?.id}).subscribe((res:any)=>{
            console.log("checkForChangeStatus", res);
            if(res.data.status == 0){
                this.snackbar.open('Your documents are still under review. Please wait. Thank you!', 'OKAY', {duration : 4000})
                this.isCheckingForUpdate = false;
                this.startCountdown(30);
            }else if(res.data.status == 1){
                const userData = this.user;
                userData.status = 1;
                this.store.setItem('loggedUser', userData); 
                const snackBarRef = this.snackbar.open('Your documents are verified! Welcome to the Phlenu Affiliate Program.', 'OKAY', {duration : 4000})
                snackBarRef.afterDismissed().subscribe(() => {
                    console.log("afterDismissed");
                    this.router.navigate(["/"]);
                });
            }else if(res.data.status == 2){
                const userData = this.user;
                userData.status = 2;
                this.store.setItem('loggedUser', userData);

                this.isCheckingForUpdate = false;
                this.startCountdown(30);

                this.snackbar.open('Oops! Your account has been rejected. Contact the Phlenu team for details. Thank you!', 'OKAY', {duration : 4000}).afterDismissed().subscribe((res)=>{
                    this.router.navigate(["/login"]);
                })   
                
            }
        })

    }

    startCountdown(seconds: number) {
        this.countdown = seconds;

        const interval = setInterval(() => {
            if (this.countdown && this.countdown > 0) {
                this.countdown--;
            } else {
                clearInterval(interval);
                this.isButtonDisabled = false;
                this.countdown = null; // Hide countdown after completion
                
            }
        }, 1000);
    }

    // checkForUpdate(){

    // }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    logout() {
        this.store.clear();
        this.router.navigate(["/login"]);
    }

    checkSelected(value) {
        // console.log(value);
    }

    removeFrontImage() {
        this.docImageUrl.front = "";
        this.docImage.front = "";
        this.docUrl.front = "";
        this.form.get("frontImage").setValue("");
    }

    removeBackImage() {
        this.docImageUrl.back = "";
        this.docImage.back = "";
        this.docUrl.back = "";
        this.form.get("backImage").setValue("");
    }
    /**
     *  For get image
     * */
    // readUrl(event, type) {
    //   if (event.target.files[0]) {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     if (type === 'front') {
    //       this.docImage.front = file;
    //       reader.onload = (_event) => {
    //         this.docImageUrl.front = reader.result.toString();
    //       };
    //     } else if (type === 'back') {
    //       this.docImage.back = file;
    //       reader.onload = (_event) => {
    //         this.docImageUrl.back = reader.result.toString();
    //       };
    //     }
    //   }
    // }

    readUrl(event: Event, type: string): void {
        const input = event.target as HTMLInputElement;

        if (input?.files?.[0]) {
            const file = input.files[0];
            const imageTypePattern = /^image\/(jpeg|png|webp)$/;
            const maxSize = 5 * 1024 * 1024; // 5MB

            // Validate file type
            if (!imageTypePattern.test(file.type)) {
                this.snackbar.open(
                    "Invalid file type. Please select an image (JPEG, PNG, or WEBP).",
                    "OKAY",
                    { duration: 5000 }
                );
                input.value = ""; // Clear the input
                return;
            }

            // Validate file size
            if (file.size > maxSize) {
                this.snackbar.open(
                    "File size exceeds 5MB. Please select a smaller image.",
                    "OKAY",
                    { duration: 5000 }
                );
                input.value = ""; // Clear the input
                return;
            }

            // Process valid image files
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                if (type === "front") {
                    this.docImage.front = file;
                    this.docImageUrl.front = reader.result?.toString() || "";
                } else if (type === "back") {
                    this.docImage.back = file;
                    this.docImageUrl.back = reader.result?.toString() || "";
                }
            };
        } else {
            this.snackbar.open("No file selected.", "OKAY", { duration: 5000 });
        }
    }

    /**
     * function used to document-upload user
     */
    uploadDocumentImage() {
        
        if (this.form.invalid) {
            return false;
        } else {
            this.isDocumentSubmitting = true; 
            const formData = new FormData();
            formData.append("image", this.docImage.front);
            this.auth
                .uploadDocumentImage(formData)
                .subscribe((response: any) => {
                    if (response?.response === 1) {
                        this.docUrl.front = response.data.url;
                    } else {
                        this.snackbar.open(response.message);
                        return; 
                    }

                    // back image
                    const formData1 = new FormData();
                    formData1.append("image", this.docImage.back);
                    this.auth
                        .uploadDocumentImage(formData1)
                        .subscribe((res: any) => {
                            if (res.response === 1) {
                                this.docUrl.back = res.data.url;
                            } else {
                                this.snackbar.open(res.message);
                                return;
                            }
                            this.uploadDocuments();
                        });
                });
        }
    }

    /**
     * function used to document-upload user
     */
    uploadDocuments() {
        this.isDocumentUploaded = false;
        const postData = {
            docType: this.form.value.docType,
            docNumber: this.form.value.docNumber,
            docFrontUrl: this.docUrl.front,
            docBackUrl: this.docUrl.back,
            selfieUrl: this.selfieImageURL,
        };
        this.auth.uploadDocuments(postData).subscribe((response: any) => {
            if (response.response === 1) {
                this.snackbar.open(response.message, 'OKAY', {duration : 4000});
                this.isDocumentUploaded = true;
                this.user.isDocumentUploaded = 1;
                this.store.setItem("loggedUser", this.user);
                this.isDocumentSubmitting = false;
            } else {
                this.snackbar.open(response.message);
                this.isDocumentUploaded = false;
                this.isDocumentSubmitting = true; 
            }
        });
    }

    onFileChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        console.log("input", input);

        if (input.files && input.files.length > 0) {
            const file = input.files[0];
            console.log("file", file);

            const validTypes = ["image/png", "image/jpeg", "image/webp"];

            if (!validTypes.includes(file.type)) {
                this.snackbar.open(
                    "Invalid file type. Please upload a PNG, JPEG, or WEBP image.",
                    "OKAY",
                    { duration: 4000 }
                );
                input.value = ""; // Reset input value to allow re-uploading the same file
                return;
            }

            this.isLoading = true;
            const reader = new FileReader();
            reader.onload = () => {
                // this.imageSrc = reader.result as string;
                this.imageSrc = reader.result?.toString() || "";

                input.value = ""; // Reset input value after processing the file
                this.uploadImageOnServer(this.imageSrc, "profile");
            };
            reader.onerror = () => {
                this.snackbar.open(
                    "Failed to load image. please try again",
                    "OKAY",
                    { duration: 2000 }
                );
                this.isLoading = false;
                input.value = ""; // Reset input value on error
            };
            reader.readAsDataURL(file);
        }
    }

    uploadImageOnServer(stringURL: string, type: string) {
        // Fetch the image as a Blob
        fetch(stringURL)
            .then((response) => {
                if (!response.ok) {
                    this.snackbar.open(
                        "Failed to load image. please try again",
                        "OKAY",
                        { duration: 2000 }
                    );
                    throw new Error("Failed to fetch the image from the URL");
                }
                return response.blob();
            })
            .then((blob) => {
                const formData = new FormData();
                formData.append("type", "affiliate");
                formData.append(
                    "image",
                    blob,
                    `${type == "profile" ? "_profile.jpg" : "_brand.jpg"}`
                ); // Add a filename
                this.auth.uploadCommonImage(formData).then((res: any) => {
                    if (res?.response == 1) {
                        this.snackbar.open(res.message, "OKAY", {
                            duration: 3000,
                        });
                        if (type == "profile") {
                            this.isLoading = false;
                            this.selfieImageURL = res.link;
                            // this.form.get("profileImage").setValue(res.link);
                        }
                    } else {
                        this.snackbar.open('Upload failed. please try again', 'OKAY', {duration : 4000})
                        this.removeImage()
                        return;
                        // this.form.get("profileImage").setValue(res.link);
                    }
                }).catch((err)=>{
                    this.snackbar.open('Upload failed. please try again', 'OKAY', {duration : 4000})
                    this.removeImage()
                    return;
                });
            })
            .catch((error) => {
                console.error("Error uploading image:", error);
            });
    }

    removeImage(): void {
        this.imageSrc = null;
        this.selfieImageURL = null;
    }
}
