import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import {LocalStoreService} from './local-store.service';
@Injectable({
  providedIn: 'root'
})
export class  DocumentUploadGuard implements CanActivate {
  
  constructor(private router: Router , private store: LocalStoreService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isDocumentUploaded()) {
      return true;
    }
    // navigate to login page as user is not authenticated
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }

  public isDocumentUploaded(): boolean {
    let status = false;
    const loggedUser = this.store.getItem('loggedUser');
    // const isDocumentUploaded = loggedUser.isDocumentUploaded;
    if (loggedUser) {
      if (loggedUser.token) {
        status = true;
      } else {
        status = false;
      }
    } else {
      status = false;
    }
    return status;
  }
}
