// export const ApiEndPointUrl = 'https://apistage.phlenu.com/api/v6/';  // 'http://54.218.126.151:8080/api/v6/';
// export const ApiEndPointUrl = 'https://api5.phlenu.com/api/v6/';
// export const BankListEndPointUrl = 'https://api5.phlenu.com/api/v2/';
// export const BaseUrl = 'https://api5.phlenu.com/';

export const environmentFlag: string = 'stage';

export const BaseUrl = environmentFlag == 'stage' ? 'https://apistage.phlenu.com/' : 'https://api5.phlenu.com/';

// export const affiliateRedirectionURL = environmentFlag == 'stage' ? 'https://stagingexpress.phlenu.com/affiliate-redirection/' : 'https://express.phlenu.com/affiliate-redirection/';
// export const affiliateRedirectionURL = environmentFlag == 'stage' ? 'http://localhost:4200/affiliate-redirection/' : 'https://express.phlenu.com/affiliate-redirection/';

export enum Pattern {
    // Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
    Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    Mobile = '[0-9]+',
    MobileMinLengthForGhana = 9,
    MobileMinLengthForNigeria = 10,
    Password = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$',
    facebookProfile = '(?:https?:\\/\\/)?(?:www\\.)?facebook\\.com\\/(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[\\w\\-]*\\/)*([\\w\\-\\.]*)?(\\/)?',
    // instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?:?(www)?instagram\\.com\\/([A-Za-z0-9-_]+)?\\S?(\\/)?',
    // instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:instagram\\.com)\\/([A-Za-z0-9-_\\.@]+)?(\\/\\?[a-z]{2}=[a-z]{2})',
    instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:instagram\\.com)\\/([A-Za-z0-9-_\\.@]+)?(?:(\\/\\?[a-z]{2}=[a-z]{2})|\\/|)',
    // youTubeChannel = '((http|https):\\/\\/|)(www\\.|)youtube\\.com\\/(channel\\/|user\\/)[a-zA-Z0-9\\-]{1,}([\\w\\-\\_]*)?(\\/)?',
    youTubeChannel = '(?:(?:http|https):\/\/)?(?:www\.)?(?:youtube\.com)\/@([A-Za-z0-9_-]+)',
    // twitterProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:twitter\\.com)\\/([A-Za-z0-9-_\\.]+)',
    xProfile = '(?:(?:http|https):\/\/)?(?:www\.)?(?:x\.com)\/([A-Za-z0-9-_\.]+)',
    tiktokProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:tiktok\\.com)\\/([A-Za-z0-9-_\\.@]+)',
    helpMessageMaxLength = 2000,
    linkvalidation = '^(https?:\\/\\/)?([\\w.-]+)\\.([a-zA-Z]{2,})([\\/\\w .-]*)*\\/?$' ,
    threads = '(?:(?:http|https):\/\/)?(?:www\.)?(?:threads\.net)\/([A-Za-z0-9_.]+)'
}


export enum country {
    Ghana = 'ghana',
    Nigeria = 'nigeria',
    US = 'us'
}


export enum countryParams {
    Ghana = 1,
    Nigeria = 2,
    US = 3
}

export enum currency {
    Ghana = 'GHS',
    Nigeria = 'NGN',
    US = 'USD'
}

export enum countryCode {
    Ghana = '+233',
    Nigeria = '+234',
    US = '+1'
}

export enum status {
    Pending = 0,
    Approved = 1,
}

export const financial = (num) => {
    // Check if the number has a decimal point
  if (String(num).includes('.')) {
    // Split the number into whole and decimal parts
    let [whole, decimal] = num.toString().split('.');
    
    // If the decimal part has more than 3 digits, truncate it 
    if (decimal.length > 3) {
      decimal = decimal.slice(0, 3);
      return `${whole}.${decimal}`;
    }
  }
  // Return the original number if no truncation is needed
  return num;
}

export const shift = 3;

export function encrypt(text, shift) {
    let result = "";

    for (let i = 0; i < text.length; i++) {
        let char = text[i];

        // Check if the character is a letter
        if (char.match(/[a-zA-Z]/)) {
            let code = text.charCodeAt(i);

            // Shift uppercase letters
            if (char >= "A" && char <= "Z") {
                code = ((code - 65 + shift) % 26) + 65;
            }
            // Shift lowercase letters
            else if (char >= "a" && char <= "z") {
                code = ((code - 97 + shift) % 26) + 97;
            }

            result += String.fromCharCode(code);
        } 
        // Check if the character is a number
        else if (char.match(/[0-9]/)) {
            let code = char.charCodeAt(0);
            // Shift numbers (0-9)
            code = ((code - 48 + shift) % 10) + 48;
            result += String.fromCharCode(code);
        } 
        else {
            // If not a letter or number, just add the character as it is
            result += char;
        }
    }

    return result;
}

// Function to decrypt a message using Caesar Cipher
export function decrypt(text, shift) {
    return encrypt(text, -shift); // Decryption is just the reverse of encryption
}


interface countriesType {
    id, selected : number,
    title, code, hdLogo, logo : string,
}


export const countries:countriesType[] = [
    { 
        title: 'Ghana',
        code: '+233',
        id : 1,
        logo : 'assets/img/flags/ghana.png',
        hdLogo : 'assets/img/flags/ghanaHD.png',
        selected: 0
    },
    { 
        title: 'Nigeria',
        code: '+234',
        id : 2,
        logo : 'assets/img/flags/nigeria.png',
        hdLogo : 'assets/img/flags/nigeriaHD.png',
        selected: 0
    }
]
