<h1 matDialogTitle class="relative mb-4 text-lg">Select Payment Method
    <div class="absolute -top-1 -right-2">
        <button mat-icon-button mat-dialog-close>
            <mat-icon [icIcon]="icClose"></mat-icon>
          </button>
    </div>
</h1>
<mat-dialog-content>
    <div class="w-full pt-2 mb-4">
        <div class="flex flex-col gap-3">
             <ng-container *ngFor="let item of this.paymentMethods">
                 <div (click)="onSelectMethods(item)" class="w-full flex justify-between p-4 border border-lightWhite rounded" [ngClass]="{'cursor-pointer' : item.isAdded ? true : false , 'border-skyBlueColor' : item.isSelected ? true : false}"  matRipple>
                    <div class="flex gap-4 items-center select-none justify-start">
                        <input type="radio" [checked]="item.isSelected" [disabled]="!item?.isAdded"/>
                        <img [src]="item.icon" alt="" class="w-6 h-6 object-contain">
                        <div>
                            <p class="text-base">{{item.title}}</p>
                            <span class="text-xs text-darkSilverColor">{{!item.isAdded ? 'Details Not Added Yet' : ''}}</span>
                        </div>
                    </div>
                    <div *ngIf="!item.isAdded" class="min-w-10 flex justify-center items-center">
                        <button  mat-stroked-button [routerLink]="item.redirect" class="sm:block hidden" mat-dialog-close>Add Detail</button>
                        <button  mat-icon-button [routerLink]="item.redirect" class="sm:hidden w-9 h-9 flex justify-center items-center border border-lightGray border-solid" mat-dialog-close>
                            <mat-icon class="w-2 h-2  text-xxs" [icIcon]="icAdd" style="margin-right: 2px;"></mat-icon>
                        </button>
                    </div>
                 </div>
             </ng-container>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="gap-3 pb-6"> 
    <button mat-raised-button color="primary" (click)="onClose()" tabindex="-1">Continue</button>
</mat-dialog-actions>