import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BaseUrl } from "../utils/systemEnums";
import { DataService } from "./data.service";
import { LocalStoreService } from "./local-store.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AuthServiceLocal {
    
    // this.userProfilePic = JSON.parse(localStorage.getItem('loggedUser'))?.profileImage;


    userProfilePic:BehaviorSubject<string> = new BehaviorSubject('');
    _configuration = {
        API_ENDPOINT: BaseUrl,
        // API_ENDPOINT_V5: ApiEndPointUrlV5,
        // BANK_API_ENDPOINT : BankListEndPointUrl,
    };

    constructor(
        private router: Router,
        private _dataService: DataService,
        private store: LocalStoreService
    ) {}

    isLoggedIn() {
        if (this.store.getItem("token")) {
            return true;
        }
        return false;
    }

    getToken() {
        return this.store.getItem("token");
    }

    getUser() {
        return this.store.getItem("loggedUser");
    }

    /**
     *  Auth Module
     * */
    public ChangePassword(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/affliate-changePassword",
            body
        );
    }

    public affiliateLogin(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + "api/v12/account/affliate-login",
            body
        );
    }

    public affiliateSignUp(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affliate-signup",
            body
        );
    }

    public checkForChangeStatus(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/get-affiliate-status",
            body
        );
    }

    public forgotPassword(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/affliate-forgotPassword",
            body
        );
    }

    public resetPassword(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/affliate-resetPassword",
            body
        );
    }

    public checkMobileExist(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + "api/v5/common/numberChecker",
            body
        );
    }

    public checkEmailExist(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT + "api/v5/common/emailChecker",
            body
        );
    }

    public sendOTPViaWhatsApp(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/common/affiliate-send-message",
            body
        );
    }

    public sendOTPViaCall(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/common/affiliate-get-otp-over-call",
            body
        );
    }

    public affiliateVerifyOTP(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/common/affiliate-verify-phone",
            body
        );
    }

    /**
     * requestWithdrawal Module
     * */
    public getWithdrawalList() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/affiliate-withdrawalList"
        );
    }

    public requestWithdrawal(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/affiliate-requestForWithdrawal",
            body
        );
    }

    /*
     * Commission Withdrawal module
     */

    public getCommissionWithdrawalList(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-CommisionWithdrawalList",
            body
        );
    }

    public requestCommissionWithdrawal(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-requestForCommisionWithdrawal",
            body
        );
    }

    public getMinMaxAmount(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-balacesheet-List",
            body
        );
    }

    /**
     * Get Total Amount
     * */
    public getCommissionWithdrawalTotalAmount(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-get-total-commision-amount",
            body
        );
    }

    /**
     * How It Works Module
     * */
    public getHowItWorksData(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + "api/v12/common/howToAffiliates",
            body
        );
    }

    /**
     * Dashboard Module
     * */
    public getDashboard() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-dashBoard"
        );
    }

    public generateLink(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/genrateDynamicLink",
            body
        );
    }

    public generatePDASLink(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/genrateDeliveryDynamicLink",
            body
        );
    }

    public customizeAffiliateRedirectionLink(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/customize-affiliate-link",
            body
        );
    }
    /**
     * User Detail
     * */
    public getProfile() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/affiliate-getProfile"
        );
    }

    /**
     * Get all Affiliated Detail
     * */
    public getAllAffiliatedUsers() {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/getListOfAffiliatesJoinedBasedOnLink",
            ""
        );
    }
    /**
     * Get Total Amount
     * */
    public getTotalAmount() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/affiliate-get-total-amount"
        );
    }

    /**
     * Bank Detail
     * */
    public getBankDetailByCountry(country) {
        return this._dataService.getData(
            this._configuration.API_ENDPOINT +
                "api/v12/common/get-all-banks/" +
                country
        );
    }     

    public getBranchListByBank(id) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/common/aff-get-branch-list/" +
                id
        );
    }

    public getBankDetailByUser() {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/get-affliate-BankDetails",
            ""
        );
    }

    public addBankDetail(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/add-affliate-details",
            body
        );
    }

    public updateBankDetail(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/update-affliate-details",
            body
        );
    }

    /**
     * Help Module
     */
    public help(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + "api/v12/account/affiliate-help",
            body
        );
    }

    public uploadDocuments(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/uploadAffiliateDocument",
            body
        );
    }

    public uploadDocumentImage(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v6/account/uploadAffiliateDocumentImage",
            body
        );
    }

    public getUserDocuments() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + "api/v12/account/getAffiliateDocs"
        );
    }

    public getOrderCommisionForAffiliate(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/getOrderCommisionForAffiliate",
            body
        );
    }

    public getAffiliateEarningTiers() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-earning-tiers"
        );
    }

    public chooseAffiliateTiear(payload) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/choose-affiliate-tiear",
            payload
        );
    }

    public getContentHIW() {
        return this._dataService.getData(
            this._configuration.API_ENDPOINT +
                "api/v12/account/get-affiliate-earning-content"
        );
    }

    public getTermsAndConditions() {
        return this._dataService.getData(
            this._configuration.API_ENDPOINT +
                "api/v12/common/get-affiliate-terms-conditions"
        );
    }

    public getGuidelineContent() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/get-affiliate-guideline-content"
        );
    }

    public getFAQsData() {
        return this._dataService.getData(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-earning-faq"
        );
    }

    public getSocialPostTemplates() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-post-template"
        );
    }

    public getToolkitData() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/affiliate-toolkit-banner"
        );
    }

    public saveAffiliateCode(body) {
        return this._dataService.putDataWithHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/account/customize-affiliate-code",
            body
        );
    }

    public checkPhoneNumber(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/common/check-affiliate-user",
            body
        );
    }

    public affiliateUserExists(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/common/check-affiliate-user-exists",
            body
        );
    }

    public affiliateSendForgotPasswordMessage(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/common/affiliate-send-forgot-password-message",
            body
        );
    }

    public affiliateSendForgotPasswordCall(body) {
        return this._dataService.postDataWithoutHeader(
            this._configuration.API_ENDPOINT +
                "api/v12/common/affiliate-forgot-password-otp-call",
            body
        );
    }

    // public affiliateVerifyPhone(body) {
    //   return this._dataService.postDataWithoutHeader(
    //        this._configuration.API_ENDPOINT + 'api/v12/common/affiliate-verify-phone', body
    //   );
    // }

    public uploadCommonImage(image) {
        return new Promise((resolve, reject)=>{
            this._dataService.postDataWithoutHeaderFormData(this._configuration.API_ENDPOINT + "api/v12/common/upload-image",image).subscribe((res:any)=>{
                resolve(res)
            }, reject);
        })
        // return this._dataService.postDataWithoutHeaderFormData(
        //     this._configuration.API_ENDPOINT + "api/v12/common/upload-image",
        //     image
        // );
    }

    public getAllBanksForMobileMoney(country) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                `api/v12/common/get-all-banks/${country}`
        );
    }

    public addMobileMoney(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                `api/v12/account/add-nupdate-affiliate-wallet-details`,
            body
        );
    }

    public onSaveProfileImage(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT +
                `api/v12/account/update-profile-pic`,
            body
        );
    }

    public getMobileMoneyDetails() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT +
                `api/v12/account/get-affilite-bank-nwallet-detail`
        );
    }

}
