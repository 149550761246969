<div class="w-full h-full document-upload-layout p-6 overflow-auto background-base">
    <div class="text-right w-full mb-4">
        <button (click)="logout()" color="primary" mat-raised-button>Logout</button>
    </div>
    <!-- document form-->
    <div @fadeInUp class="card w-full max-w-xs" *ngIf="!isDocumentUploaded">
        <div class="text-center px-6 pt-6">
            <h2 class="title mb-1 mt-2">Please upload your Document here for approval</h2>
            <h6 class="font-thin">Verify Your Identity 🛡️</h6>
        </div>

        <div [formGroup]="form" class="px-6 pb-6" fxLayout="column" fxLayoutGap="16px">
            
            <div fxLayout="column">
                <div class="grid py-4 sm:grid-cols-1 grid-cols-1">
                    <div class="profile_image w-full"> 
                        <div class="flex justify-center items-end">
                            <div
                                class="flex flex-col items-center  mt-3 justify-center profilePicWrapper shadow-4 rounded-lg border border-dashed border-darkSilverColor  relative">
                                <!-- Placeholder -->
                                <ng-container *ngIf="!imageSrc">
                                    <div class="flex flex-col items-center justify-center w-full h-full cursor-pointer hover:bg-primaryBG transition duration-300"
                                        (click)="fileInput.click()">
                                        <div class="text-gray-500 text-4xl leading-none">+</div>
                                        <p class="mt-1 text-sm leading-none text-gray-600">Add Selfie</p>
                                    </div>
                                </ng-container>

                                <!-- Uploaded Image -->
                                <ng-container *ngIf="!isLoading ? (imageSrc) : (!isLoading && imageSrc)">
                                    <img [src]="imageSrc" alt="Profile Picture" class="w-full h-full object-cover" />
                                </ng-container>

                                <!-- Loader -->
                                <div *ngIf="isLoading" class="loader-container">
                                    <div class="loader"></div>
                                </div>


                                <input #fileInput type="file" class="hidden" (change)="onFileChange($event)"
                                    accept="image/png, image/jpeg, image/webp" />

                                <div class="absolute bottom-0 z-10 -right-12">
                                    <button mat-icon-button class="text-sm transition"
                                        *ngIf="!isLoading ? (imageSrc) : (!isLoading && imageSrc)"
                                        (click)="removeImage('profile')">
                                        <mat-icon [icIcon]="icRemove"
                                            class="text-contrast-white shadow-8 text-sm"></mat-icon>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div class="mt-4 flex flex-col">
                            <span class="text-xxs text-lightPink">Aspect Ratio: 1:1 | Allowed Types: PNG, JPEG,
                                WEBP</span>
                        </div>
                       
                    </div>
                </div>
            </div>
 
            <div fxLayout="column">
                
                <mat-form-field class="flex-auto mb-3">
                    <mat-label>Document Type</mat-label>
                    <mat-select class="text-capitalize" (selectionChange)="checkSelected($event.value)" required
                        formControlName="docType">
                        <mat-option class="text-capitalize" *ngFor="let doc of docList" [value]="doc">
                            {{doc}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('docType').hasError('required')">{{message.field.required}}</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-auto mb-3">
                    <mat-label>Document Number</mat-label>
                    <input type="text" formControlName="docNumber" matInput required>
                    <mat-error *ngIf="form.get('docNumber').hasError('required')">{{message.field.required}}</mat-error>
                </mat-form-field>

                <div class="mb-2">
                    <label class="d-block mb-2">Upload Document <small class="hint-text d-block">(JPEG, PNG, or WEBP |
                            Max size: 2MB)</small></label>
                    <div class="flex flex-row">
                        <div class="flex-1 text-center">
                            <label *ngIf="!docImageUrl.front" class="mt-2 mb-2 image-box">
                                <input type="file" (change)="readUrl($event, 'front')" accept="image/*"
                                    formControlName="frontImage" hidden required>
                                <mat-icon [icIcon]="icUpload" color="primary"></mat-icon>
                            </label>

                            <div *ngIf="docImageUrl.front" class="mt-2 mb-2 relative image-box">
                                <img [src]="docImageUrl.front">
                                <div class="absolute -top-1 -right-1 z-10">
                                    <button (click)="removeFrontImage()" mat-icon-button>
                                        <mat-icon class="text-midBlack text-sm" [icIcon]="icClose"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <label class="d-block mb-3 caption">Front Image</label>
                        </div>

                        <div class="flex-1 text-center">
                            <label *ngIf="!docImageUrl.back" class="mt-2 mb-2 image-box">
                                <input type="file" (change)="readUrl($event, 'back')" accept="image/*"
                                    formControlName="backImage" required hidden>
                                <mat-icon [icIcon]="icUpload" color="primary"></mat-icon>
                            </label>

                            <div *ngIf="docImageUrl.back" class="mt-2 mb-2 relative image-box">
                                <img [src]="docImageUrl.back">
                                <div class="absolute -top-1 -right-1 z-10">
                                    <button (click)="removeBackImage()" mat-icon-button>
                                        <mat-icon class="text-midBlack text-sm" [icIcon]="icClose"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <label class="d-block mb-3 caption">Back Image</label>
                        </div>
                    </div>
                </div>

                <div class="text-xs font-light leading-snug my-4">
                    <p class="text-xs font-light leading-snug">For security and smooth payouts, please upload:</p>
                    <ul>
                        <li>
                            📌 A copy of your government-issued ID
                        </li>
                        <li>📸 A clear, non-blurry photo of yourself holding your ID</li>
                    </ul>
                    <p class="text-xs font-light leading-snug">Your info is 100% secure and used only for verification. ✅</p>
                </div>

                <button (click)="uploadDocumentImage()" color="primary" mat-raised-button [disabled]="form.invalid">
                    Submit
                </button>
            </div>
        </div>
    </div>
    <!-- document form-->

    <!-- document submitted-->
    <div class="card w-full max-w-3xl p-6 text-center animate-fadeIn shadow-lg rounded-lg bg-[#181f2f] mx-auto mt-8 sm:mt-16"
        *ngIf="isDocumentUploaded">
        <img src="assets/img/time-left.png" alt="Waiting for Approval" class="mx-auto w-20 mb-4 mt-3 animate-bounce">

        <h2 @fadeInUp class="title text-lg font-bold text-gray-700 uppercase mb-4">
            Waiting for Approval
        </h2>

        <p @fadeInUp class="text-gray-600 leading-normal mb-6">
            Thank you <span class="font-semibold">{{this.user.firstName}} {{this.user.lastName}}</span> for uploading
            your
            documents!
            We are currently reviewing your profile to ensure everything is in order.
            This process typically takes <strong>24-72 hours</strong>, and we’ll notify you of the outcome as soon as
            possible.
        </p>

        <p @fadeInUp class="text-gray-600 leading-normal mb-4">
            You’ll receive an update via <strong>email</strong> or <strong>phone</strong> once your profile has been
            reviewed.
            In the meantime, if you have any questions or need assistance, feel free to contact us at
            <a href="mailto:customer@phlenu.com" class="text-blue-500 underline">{{'customer@phlenu.com'}}</a>
            or on WhatsApp:
            <ng-container *ngIf="this.user.country == 'ghana'; else ngnCountry">
                <strong class="block mt-3 leading-normal">+233554867581 (Ghana)</strong>
            </ng-container>
            <ng-template #ngnCountry>
                <strong class="block mt-3 leading-normal">+234818391923 (Nigeria)</strong>
            </ng-template>
        </p>

        <p @fadeInUp class="text-gray-700 leading-normal font-semibold">
            Thank you for your patience and for choosing Phlenu!
        </p>

        <div @fadeInUp class="mt-6 leading-normal">
            <h4 class="text-gray-800 font-bold">Thanks,</h4>
            <h5 class="text-gray-700">Team Phlenu</h5>
        </div>
        <!-- document submitted-->
    </div>
</div>